import en from './lang/en.json'
import th from './lang/th.json'

export default defineI18nConfig(() => ({
  legacy: true,
  locale: 'th',
  fallbackLocale: 'en',
  messages: {
    th,
    en,
  },
}))
